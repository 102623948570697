import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './styles/index.css';
import './styles/resets.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Layout from './pages/layout';
import Index from './pages/index';
import Upload from './pages/upload';
import QrScan from './pages/qrscan';
import Map from './pages/maps';
import Favourites from './pages/favourites';
import Settings from './pages/settings';
import Notes from './pages/notes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Index />} />
          <Route path="upload" element={<Upload />} />
          <Route path="qrscan" element={<QrScan />} />
          <Route path="map" element={<Map />} />
          <Route path="favourites" element={<Favourites />} />
          <Route path="settings" element={<Settings />} />
          <Route path="notes" element={<Notes />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
