import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Popup, Marker} from 'react-leaflet';


// Main component that sets up the map and markers
const MapWithFixedPopup = ({ position }) => {
    
    return (
        <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
           
                <Marker position={position}>
                    <Popup>Hello</Popup>
                </Marker>

            </MapContainer>
    );
};

export default MapWithFixedPopup;
