import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faImage } from '@fortawesome/pro-regular-svg-icons';

export default function Upload() {
 
    return (
      <div className="upload-page-content">
          <header className="header-upload">
            <h1>Upload</h1>
          </header>

          <form className="form form-upload">
          
          <div className='image-upload-container'>
            <label for="camera" className='upload-camera'>
              <FontAwesomeIcon icon={faCamera} />
              Camera
              </label>
            <input type="file" id="camera" name="file" multiple></input>

            <label for="gallery" className='upload-gallery'>
              <FontAwesomeIcon icon={faImage} />
              Gallery
              </label>
            <input type="file" id="gallery" name="file" multiple></input>

          </div>
            <label for="title">Title:</label>
            <input type="text" id="title" name="title"></input>

            <label for="notes">Notes:</label>
            <textarea id="notes" name="notes"></textarea>

            <input type="submit" value="Submit"></input>
          </form>
      </div>
    );
  }
