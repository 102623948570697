import React from 'react';
import { Outlet } from "react-router-dom";
import BottomNav from '../components/bottom-nav';

export default function Layout() {
    return (
        <>
            <div className="page-content">
                <Outlet />
                <BottomNav />
            </div>
        </>
    )
};

