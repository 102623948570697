import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faQrcode, faLocationDot, faHeart, faGear, faNote } from '@fortawesome/pro-regular-svg-icons';

export default function BottomNav() {
    return <>
        <div id="bottom-nav" className="bottom-nav-container">
            <Link to="/upload" className="bottom-nav__btn">
                <FontAwesomeIcon icon={faUpload} />
                Upload
            </Link>
            <Link to="/map" className="bottom-nav__btn">
                <FontAwesomeIcon icon={faLocationDot} />
                Map
            </Link>
            <Link to="/favourites" className="bottom-nav__btn">
                <FontAwesomeIcon icon={faNote} />
                Notes
            </Link>
            <Link to="/favourites" className="bottom-nav__btn">
                <FontAwesomeIcon icon={faHeart} />
                Favourties
            </Link>
            <Link to="/qrscan" className="bottom-nav__btn">
                <FontAwesomeIcon icon={faQrcode} />
                QR Scan
            </Link>
            <Link to="/settings" className="bottom-nav__btn">
                <FontAwesomeIcon icon={faGear} />
                Settings
            </Link>
        </div>
    </>
}