import React, { useState, useEffect } from 'react';
import MapWithFixedPopup from "../components/map-fixed-popup";
export default function Map() {

  const [position, setPosition] = useState();
  
    // get user's location
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setPosition([position.coords.latitude, position.coords.longitude]);
        });
    }, []);

    // ternary operator, if no position return null, otherwise return the map
  return !position ? null : (
    <div className="map-page-content">
      <MapWithFixedPopup position={position}/>
    </div>
  );
}
